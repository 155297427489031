import {
  FbLoginStatus,
  GetFbLoginStatusResponse,
} from '@components/FBPlugins/FbLoginStatus';
import { useCallback, useEffect, useState } from 'react';
import { callFacebookSDK } from '@components/FBPlugins/callFacebookSDK';
import { useMutation } from '@apollo/react-hooks';
import { Level, log } from 'cf-common/src/logger';
import {
  showErrorToaster,
  showSomethingWentWrongToaster,
} from '@services/MessageService';
import { useFbLoginStatus } from '@components/FBPlugins/useFbLoginStatus';
import { Location } from 'history';
import { usePermissionsGroup } from '@utils/Data/Permissions/usePermissionsGroup';
import { getAuthToken } from '@utils/Auth/getAuthToken';
import { sendEvent } from '@utils/Analytics';
import { useSetUserLocale } from '@utils/Data/Admin/Locale/useSetUserLocale';
import { useHistory } from 'react-router-dom';
import { PermissionGroup } from '@utils/Permissions';
import { TokenError } from '@globals';
import { setAuthToken } from '../utils/setAuthToken';
import { getQueryObjectStringified } from '../utils/query';
import {
  GetCfTokenOnAccessTokenMutation,
  GetCfTokenOnAccessTokenMutationVariables,
} from './@types/GetCfTokenOnAccessTokenMutation';
import { GET_CF_TOKEN_ON_ACCESS_TOKEN_MUTATION } from './queries';
import { afterLoginActions } from '../utils/afterLoginActions';
import { sendAfterLoginEvents } from '../utils/sendAfterLoginEvents';
import { useFbSdkStatus } from './useFbSdkStatus';
import { redirectToAwesomePanel } from '@utils/AuthFlow/redirectToAwesomePanel';
import { SWITCH_FROM_AWESOME_PANEL_API_QUERY_PARAM_NAME } from '@utils/switchFromAwesomePanel';
import { isNeedForceAuth } from '@utils/force-auth';

const DEFAULT_PERMISSION_GROUP = PermissionGroup.full_and_marketing;

interface UseFacebookLoginArgs {
  location: Location;
  interruptedHref?: string;
  connectAccountOnly?: boolean;
  reRequestPermissions?: boolean;
  ignoreAutologin?: boolean;
  onAccountConnected?(): void;
  onAccountConnectFailed?(error: TokenError): void;
}

export const useFacebookLogin = ({
  interruptedHref,
  location,
  connectAccountOnly,
  reRequestPermissions,
  onAccountConnectFailed,
  onAccountConnected,
  ignoreAutologin,
}: UseFacebookLoginArgs) => {
  const [
    isAutologinFromFbTokenImpossible,
    setIsAutologinFromFbTokenImpossible,
  ] = useState<boolean>(false);
  const setUserLocale = useSetUserLocale();
  const { fbSdkReady, fbSdkError } = useFbSdkStatus();
  const fbLoginStatus = useFbLoginStatus();
  const history = useHistory();
  const {
    permissionsGroup: permissionsGroupFull,
    loading: loadingPermissionsGroup,
  } = usePermissionsGroup(DEFAULT_PERMISSION_GROUP);

  const [
    getCfTokenOnAccessTokenMutation,
    {
      called: getCfTokenOnAccessTokenMutationCalled,
      loading: getCfTokenOnAccessTokenMutationLoading,
      data: getCfTokenOnAccessTokenMutationLoadingData,
    },
  ] = useMutation<
    GetCfTokenOnAccessTokenMutation,
    GetCfTokenOnAccessTokenMutationVariables
  >(GET_CF_TOKEN_ON_ACCESS_TOKEN_MUTATION, {
    onError: (error) => {
      log.warn({ error, msg: 'Error while generating token on login page' });
      showSomethingWentWrongToaster();
      setIsAutologinFromFbTokenImpossible(true);
    },
    onCompleted: async ({
      generateTokenFromSdk: {
        token,
        error_type: connectAccountError,
        awesome_panel_auth_token,
        redirect_to_awesome_panel,
      },
    }) => {
      if (awesome_panel_auth_token && redirect_to_awesome_panel) {
        redirectToAwesomePanel(awesome_panel_auth_token);
        return;
      }
      if (token && !connectAccountError) {
        onAccountConnected?.();
      }
      if (!reRequestPermissions && !connectAccountOnly && token) {
        await setAuthToken(token);
        await sendAfterLoginEvents();
        await setUserLocale();
        await afterLoginActions(interruptedHref);
        return;
      }
      if (connectAccountError && !onAccountConnectFailed) {
        switch (connectAccountError) {
          case TokenError.CHATFUEL_ACCOUNT_CONFLICT:
          case TokenError.FB_PROFILE_CONFLICT:
            showErrorToaster(
              'pages.MultiSystemAuth.fbConnect.alreadySignedUp',
              undefined,
              10 * 1000,
            );
            sendEvent({
              category: 'connect fb account',
              action: 'already signed up',
            });
            break;
          default:
            showSomethingWentWrongToaster();
        }
      }

      if (connectAccountError && onAccountConnectFailed) {
        onAccountConnectFailed(connectAccountError);
      }
    },
  });

  const getCfTokenOnAccessToken = useCallback(
    async ({ authResponse, status }: GetFbLoginStatusResponse) => {
      const accessToken = authResponse?.accessToken;
      if (status === FbLoginStatus.connected && accessToken) {
        const authToken = getAuthToken();
        if (connectAccountOnly && !authToken) {
          log({
            msg: 'No auth token for connect fb account',
            level: Level.warn,
          });
          showSomethingWentWrongToaster();
          return;
        }

        await getCfTokenOnAccessTokenMutation({
          variables: {
            accessToken,
            queryObjectStringified: getQueryObjectStringified(
              location.search,
              interruptedHref,
              [],
              isNeedForceAuth()
                ? { [SWITCH_FROM_AWESOME_PANEL_API_QUERY_PARAM_NAME]: 'true' }
                : {},
            ),
            permissionGroup: DEFAULT_PERMISSION_GROUP,
            authToken: connectAccountOnly ? authToken : undefined,
          },
        });
      } else {
        setIsAutologinFromFbTokenImpossible(true);
      }
    },
    [
      connectAccountOnly,
      getCfTokenOnAccessTokenMutation,
      interruptedHref,
      location.search,
    ],
  );

  useEffect(() => {
    if (
      fbLoginStatus &&
      !getCfTokenOnAccessTokenMutationCalled &&
      !connectAccountOnly &&
      !reRequestPermissions &&
      !ignoreAutologin
    ) {
      getCfTokenOnAccessToken(fbLoginStatus);
    }
  }, [
    ignoreAutologin,
    connectAccountOnly,
    fbLoginStatus,
    getCfTokenOnAccessToken,
    getCfTokenOnAccessTokenMutationCalled,
    reRequestPermissions,
  ]);

  const facebookLogin = useCallback(
    () =>
      new Promise((resolve) => {
        if (fbSdkReady) {
          callFacebookSDK((FB) => {
            FB.login(
              (responce: GetFbLoginStatusResponse) => {
                resolve(getCfTokenOnAccessToken(responce));
              },
              {
                scope: permissionsGroupFull?.join(','),
              },
            );
          });
          return;
        }

        if (connectAccountOnly || reRequestPermissions) {
          history.push({
            pathname: '/autopermissionsrequest',
            state: {
              interruptedHref,
              permissionGroup: PermissionGroup.full_and_marketing,
            },
          });
          return;
        }
        history.push({
          ...history.location,
          search: interruptedHref
            ? new URLSearchParams({ interruptedHref }).toString()
            : '',
          pathname: '/fb-autologin',
        });
      }),
    [
      connectAccountOnly,
      fbSdkReady,
      getCfTokenOnAccessToken,
      history,
      interruptedHref,
      permissionsGroupFull,
      reRequestPermissions,
    ],
  );

  const inProgressFacebookLogin =
    getCfTokenOnAccessTokenMutationLoading ||
    !!getCfTokenOnAccessTokenMutationLoadingData;

  return {
    facebookLogin,
    inProgressFacebookLogin,
    ready:
      (fbSdkReady || fbSdkError) &&
      !loadingPermissionsGroup &&
      !!fbLoginStatus &&
      (isAutologinFromFbTokenImpossible ||
        connectAccountOnly ||
        reRequestPermissions ||
        ignoreAutologin),
  };
};
